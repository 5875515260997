import React from 'react';
import BaseLayoutTemplate from '../components/environments/BaseLayoutTemplate';
import styled from 'styled-components';
import {
  CROCCHA_API,
  MetaDesc,
  MetaOGP,
  SDGs01, SDGs02, SDGs03, SDGs04, SDGs05, SDGs06, SDGs07, SDGs08, SDGs09, SDGs10,
  SDGs11, SDGs12, SDGs13, SDGs14, SDGs15, SDGs16
} from '../utils';
import useMedia from '../customHooks/userMedia';
import HeadBase from '../components/molecules/HeadBase';
import SdgsHandmadeSearviceForm from '../components/organisms/SdgsHandmadeSearviceForm';
import LazyLoadImage from '../components/atoms/LazyLoadImage';
import {
  textColor,
  headingFontSize,
  fontSize,
  breakpoint
} from '../components/theme';
import { useFetchPost } from '../customHooks/useFetchPost';
import { navigate } from '@reach/router';
import OtherService from '../components/molecules/OtherService';

const SdgsHandmadePage = () => {
  const isMobile = useMedia('(max-width: 767px)');
  const { sendMail, submitStatus } = useFetchPost(() =>
    navigate('/contact/success', { state: { fromPage: 'contact' } })
  );

  const loaded = submitStatus !== 'working';

  return (
    <BaseLayoutTemplate loaded={loaded}>
      <HeadBase
        metas={{
          title: 'SDGs × ハンドメイド | tryangle株式会社',
          description: MetaDesc,
          openGraph: {
            type: 'article',
            url: `${CROCCHA_API.SELF_DOMAIN}/sdgs-handmade/`,
            title: 'SDGs × ハンドメイド | tryangle株式会社',
            description: MetaDesc,
            images: [
              {
                url: MetaOGP,
                width: 1200,
                height: 630,
                alt: 'tryangle株式会社',
              },
            ],
            site_name: 'tryangle株式会社',
          },
        }}
      />

      {/* メインビジュアル */}
      <TopSection>
        <TopInner>
          <TopImage src={SDGs02} alt='SDGs×ハンドメイド' />
          <h1>
            <TopTitle src={SDGs03} alt='SDGs×ハンドメイド' />
          </h1>
        </TopInner>
      </TopSection>

      <Section>
        <CatchphraseInner>
          <StyledImage src={SDGs04} alt='SDGs' />
          <Catchphrase>
            <TopText>余っている<span>『素材』</span>や</TopText>
            <Middletext>捨てている<span>『材料』</span></Middletext>
            <BottomText>ありませんか？</BottomText>
          </Catchphrase>
        </CatchphraseInner>
        <MaterialImage src={SDGs05} alt='SDGs' />
        <DecoratedTitle>アップサイクル商品として生まれ変わらせます！</DecoratedTitle>
      </Section>

      <Section>
        <FeaturesTitle>社会貢献だけではありません<span>！</span></FeaturesTitle>
        <FeaturesList>
          <li>
            <FeaturesListTitle>廃棄費用削減</FeaturesListTitle>
            <FeaturesListText>廃棄処理費用を削減することができます！</FeaturesListText>
          </li>
          <li>
            <FeaturesListTitle>顧客開拓</FeaturesListTitle>
            <FeaturesListText>弊社のもつcrocchaユーザー10万人へのアプローチが可能です！</FeaturesListText>
          </li>
          <li>
            <FeaturesListTitle>ブランド認知・価値向上</FeaturesListTitle>
            <FeaturesListText>SDGsを重視した本取り組みは弊社ブランド認知・価値向上への寄与が期待できます！</FeaturesListText>
          </li>
        </FeaturesList>
      </Section>

      <Section>
        <H2>どういう取り組みができますか？</H2>
        <Ol>
          <li>
            <OlTop>
              <h3>ハンドメイドでアップサイクル商品として生まれ変わらせます</h3>
              <p>
                弊社、パートナー企業のもつ材料と組み合わせてユーザー様にグッズやアクセサリーを制作頂きます。
                ※ユーザー様の商品が販売できるECサイトや卸販売、アンテナショップ等販売先を予定しております。
              </p>
            </OlTop>
            <ImagesWrap>
              <StyledImage src={SDGs08} alt='ハンドメイド素材セット' />
              <StyledImage src={SDGs09} alt='ハンドメイド素材セット' />
              <StyledImage src={SDGs10} alt='ハンドメイド素材セット' />
            </ImagesWrap>
          </li>
          <li>
            <OlTop>
              <h3>ハンドメイド素材セットをつくります</h3>
              <p>
                弊社、パートナー企業のもつ材料と組み合わせて、素材の詰め合わせパックを作り、弊社ECサイトやパートナー企業のお店等での販売を予定しております。
              </p>
            </OlTop>
            <OlImage src={SDGs11} alt='ハンドメイド素材セット' />
          </li>
          <li>
            <OlTop>
              <h3>お子様向け工作キットをつくります</h3>
              <p>
                夏休み、自由課題、自由研究等に使える工作キット等をつくり、販売いたします。
              </p>
            </OlTop>
            <ImagesWrap2>
              <StyledImage src={SDGs12} alt='ハンドメイド素材セット' />
              <StyledImage src={SDGs13} alt='ハンドメイド素材セット' />
            </ImagesWrap2>
          </li>
        </Ol>
        <Promotion>
          <div>
            その他、企画やプロモーションも<br />お手伝いします
          </div>
          <p>
            余りものを活用して自社製品をつくりたい企業様には弊社のユーザー様に素材を提供しアイデアを出して頂き、商品開発をお手伝い致します。また取り組み等をご一緒にプロモーションさせて頂きます。
          </p>
        </Promotion>
      </Section>

      <Section>
        <H2>商材は何でもOKですか？</H2>
        <Products>
          <ProductsTitle>
            ハンドメイドで使用できるものに限ります。<br />
            不明な場合は一度お問い合わせください！
          </ProductsTitle>
          <ProductsWrap>
            <BalloonImage src={SDGs14} alt='OKな商材' />
            <ProductsText>
              布、ボタン、木材、紙材、紐、リボン、皮、糸、アクセサリーパーツ類など<br />
              破棄予定、規格外の衣服、アクセサリーなど
            </ProductsText>
          </ProductsWrap>
          <ProductsWrap2>
            <BalloonImage src={SDGs14} alt='NGな商材' />
            <ProductsText>
              大型品、1mを超えるプラスチック製品、<br />
              生もの、食材、危険品、タイヤ、加工が必要な金属品、素材として使用できないものなど
            </ProductsText>
          </ProductsWrap2>
        </Products>
      </Section>

      <Section>
        <H2>今後の流れ</H2>
        <Flow2>
          <FlowImage src={SDGs15} alt='NGな商材' />
          <FlowInner>
            <div>お問い合わせ後、面談</div>
            <p>
              オンライン面談やお電話にて商品、端材、量などご相談させて頂きます。ご訪問させて頂く場合もございます。
            </p>
          </FlowInner>
        </Flow2>
        <Flow>
          <FlowImage src={SDGs16} alt='NGな商材' />
          <FlowInner>
            <div>弊社宛に発送</div>
            <p>
              販売までの全ての業務は弊社が行います。
              プロモーション等の企画については別途ご相談させて頂きます。
            </p>
          </FlowInner>
        </Flow>
      </Section>

      {/* お問い合わせ */}
      <Section>
        <ContactH2>お問い合わせはこちら</ContactH2>
        <SdgsHandmadeSearviceForm sendMail={sendMail} submitStatus={submitStatus} />
      </Section>

      {/* その他のサービス */}
      <OtherService page='sdgs-handmade' />
    </BaseLayoutTemplate>
  );
};

export default SdgsHandmadePage;


// 共通
const Section = styled.section`
  font-family: "Noto Sans JP";
  font-weight: normal;
  margin-bottom: 40px;
  padding: 0 16px;

  @media screen and (max-width: 767px) {
    max-width: 420px;
  }
  @media ${breakpoint.tab} {
    max-width: 1000px;
    margin-bottom: 80px;
  }
  @media ${breakpoint.tabOnly} {
    max-width: 100vw;
    padding: 0 32px;
  }
`;

const H2 = styled.h2`
  position: relative;
  background-color: #EB8D8D;
  color: #fff;
  margin: 0 -16px 32px;
  padding: 12px 0;
  text-align: center;

  @media ${breakpoint.tab} {
    font-size: 26px;
    margin-bottom: 48px;
    padding: 16px 0 20px;

    /* 背景色を100vwで表示 */
    width: 100vw;
    position: relative;
    left: calc(50% + 16px);
    transform: translateX(-50%);
  }

  &::after {
    content: '';
    position: absolute;
    left: calc(50% - 8px);
    bottom: -15px;
    border-right: 12px solid transparent;
    border-bottom: 16px solid #EB8D8D;
    border-left: 12px solid transparent;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }
`;

const StyledImage = styled(LazyLoadImage)`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;


// SDGs×ハンドメイド
const TopSection = styled.section`
  position: relative;
  background-image: url(${SDGs01});
  background-size: contain;
  margin: 0 0 32px;
  height: calc(100vw * 0.558);
  width: 100vw;

  @media ${breakpoint.tab} {
    background-size: 860px;
    background-position: top right 38%;
    height: calc(860px * 0.558);

    &::after {
      content: '';
      display: block;
      background-color: #EA8D8D;
      height: 100%;
      width: 40%;
      z-index: -1;
    }
  }
  @media ${breakpoint.tabOnly} {
    background-size: 90vw;
    background-position: top right;
    height: calc(90vw * 0.558);

    &::after {
      width: 20%;
      z-index: -1;
    }
  }
  @media screen and (min-width: 2000px) {
    &::after {
      width: 50vw;
    }
  }
`;

const TopInner = styled.div`
  position: absolute;
  bottom: 24px;
  left: 16px;

  @media ${breakpoint.tab} {
    bottom: 48px;
    left: calc(50% - 360px);
    width: 720px;
  }
`;

const TopTitle = styled(StyledImage)`
  width: 40vw;

  @media ${breakpoint.tab} {
    width: 340px;
  }
  @media ${breakpoint.tabOnly} {
    width: 300px;
  }
`;

const TopImage = styled(StyledImage)`
  margin-bottom: 12px;
  width: 26vw;

  @media ${breakpoint.tab} {
    margin-bottom: 32px;
    width: 180px;
  }
  @media ${breakpoint.tabOnly} {
    width: 140px;
  }
`;


// 捨てている素材ありませんか？
const CatchphraseInner = styled.div`
  @media ${breakpoint.tab} {
    display: flex;
    max-width: 720px;
    margin: 40px auto;
  }
`;

const Catchphrase = styled.h2`
  font-size: ${headingFontSize.large};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 32px auto 40px;

  span {
    font-size: 26px;
  }

  @media ${breakpoint.tab} {
    font-size: ${headingFontSize.xLarge};
    margin-left: 32px;
    span {
      font-size: 30px;
    }
  }
`;

const TopText = styled.div`
`;
const Middletext = styled.div`
  width: 100%;
  text-align: right;
  @media ${breakpoint.tab} {
    margin: 12px 0;
  }
`;
const BottomText = styled.div`
  font-size: 32px;
  margin-top: 8px;
  width: 100%;
  text-align: center;
  @media ${breakpoint.tab} {
    font-size: 36px;
    margin-top: 0;
  }
`;

const MaterialImage = styled(StyledImage)`
  @media ${breakpoint.tab} {
    display: flex;
    margin: auto;
    width: 600px;
  }
`;

const DecoratedTitle = styled.div`
  position: relative;
  color: #fff;
  background-color: #D29101;
  font-size: ${headingFontSize.small};
  font-weight: bold;
  margin-top: 40px;
  padding: 12px 0;
  width: calc(100% - 6px);
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    top: 6px;
    left: 6px;
    border: 1px solid #D29101;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  @media ${breakpoint.tab} {
    font-size: 26px;
    margin: 80px auto 0;
    width: 70%;
  }
  @media ${breakpoint.tabOnly} {
    width: 100%;
  }
  @media screen and (max-width: 374px) {
    font-size: 4vw;
  }
`;


// 社会貢献だけではありません！
const FeaturesTitle = styled.h2`
  position: relative;
  font-size: 22px;
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    display: block;
    background-color: #FDED01;
    width: 100%;
    height: 8px;
    z-index: -1;
  }

  span {
    display: inline-block;
    font-size: 24px;
    transform: rotate(20deg);
  }

  @media ${breakpoint.tab} {
    font-size: 38px;
    max-width: 720px;
    margin: 0 auto;
    &::after {
      left: 9%;
      width: 80%;
      height: 12px;
    }

    span {
      font-size: 40px;
    }
  }

  @media screen and (max-width: 374px) {
    font-size: 6vw;
    span {
      font-size: 6vw;
    }
  }
`;

const FeaturesList = styled.ul`
  position: relative;
  background-color: #FFFFBF;
  margin-top: 40px;
  padding: 24px;

  @media ${breakpoint.tab} {
    max-width: 720px;
    margin: 60px auto 0;
    padding: 32px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    right: 0;
    display: block;
    background-size: contain;
  }

  &::before {
    top: -24px;
    background-image: url(${SDGs06});
    width: 67px;
    height: 97px;
  }

  &::after {
    bottom: -16px;
    background-image: url(${SDGs07});
    width: 64px;
    height: 72px;
  }

  @media ${breakpoint.tab} {
    &::before {
      width: 110px;
      height: 150px;
    }
    &::after {
      width: 90px;
      height: 100px;
    }
  }

  li {
    position: relative;
    margin-bottom: 16px;
    padding-left: 32px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: calc(50% - 10px);
      left: 0;
      display: block;
      border: 2px solid #E81921;
      border-radius: 50%;
      height: 20px;
      width: 20px;
    }
    &::after {
      height: 14px;
      width: 14px;
      top: calc(50% - 7px);
      left: 3px;
    }
  }

  @media ${breakpoint.tab} {
    li {
      margin-bottom: 24px;
      padding-left: 44px;

      &::before,
      &::after {
        top: calc(50% - 12px);
        height: 30px;
        width: 30px;
      }
      &::after {
        height: 22px;
        width: 22px;
        top: calc(50% - 8px);
        left: 4px;
      }
    }
  }
`;

const FeaturesListTitle = styled.div`
  font-size: ${headingFontSize.large};
  font-weight: bold;
  @media ${breakpoint.tab} {
    font-size: ${headingFontSize.xLarge};
  }
`;

const FeaturesListText = styled.div`
  font-size: ${fontSize.small};
  @media ${breakpoint.tab} {
    font-size: ${fontSize.default};
  }
`;


// どういう取り組みができますか？
const Ol = styled.ol`
  counter-reset: item;

  li {
    position: relative;
    margin-bottom: 40px;

    &::before {
      counter-increment: item;
      content: counter(item);
      position: absolute;
      top: -4px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #D29101;
      border-radius: 50%;
      color: #fff;
      font-weight: bold;
      height: 32px;
      width: 32px;
      padding-bottom: 1px;
    }

    &:first-child {
      &::before {
        top: 8px;
      }
    }
  }

  @media ${breakpoint.tab} {
    max-width: 720px;
    margin: 0 auto;

    li {
      margin-bottom: 60px;

      &::before {
        top: 0;
        height: 38px;
        width: 38px;
      }

      &:first-child {
        &::before {
          top: 0;
        }
      }
    }
  }
`;

const OlTop = styled.div`
  margin-bottom: 24px;
  padding: 0 8px 0 40px;

  h3 {
    font-size: ${headingFontSize.medium};
    line-height: 1.5;
    margin-bottom: 8px;
  }
  p {
    font-size: ${fontSize.default};
  }

  @media ${breakpoint.tab} {
    margin-bottom: 32px;
    padding-left: 48px;
    h3 {
      font-size: ${headingFontSize.xLarge};
      margin-bottom: 12px;
    }
    p {
      font-size: ${fontSize.large};
    }
  }
`;

const Promotion = styled.div`
  margin: 60px auto;
  div {
    font-size: ${headingFontSize.large};
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 12px;
    text-align: center;
  }
  p {
    font-size: ${fontSize.default};
    line-height: 1.8;
  }

  @media ${breakpoint.tab} {
    max-width: 720px;
    margin: 0 auto;
    div {
      font-size: 32px;
    }
    p {
      font-size: ${fontSize.large};
      padding: 12px;
    }
  }
`;

const ImagesWrap = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 8px;

  @media ${breakpoint.tab} {
    grid-gap: 16px;
  }
`;

const ImageSize = (343 - 16) / 3;
const ImageSizePC = (720 - 32) / 3;

const ImagesWrap2 = styled.div`
  display: grid;
  grid-template-columns: ${ImageSize}px ${ImageSize}px;
  justify-content: center;
  grid-gap: 8px;
  @media ${breakpoint.tab} {
    grid-template-columns: ${ImageSizePC}px ${ImageSizePC}px;
    grid-gap: 16px;
  }
`;

const OlImage = styled(StyledImage)`
  display: flex;
  align-items: center;
  margin: auto;
  width: 80%;
  @media ${breakpoint.tab} {
    width: 300px;
  }
`;


// 商材は何でもOKですか？
const Products = styled.div`
  @media ${breakpoint.tab} {
    max-width: 720px;
    margin: 0 auto;
  }
`;

const ProductsTitle = styled.div`
  font-size: ${headingFontSize.medium};
  font-weight: bold;
  margin-bottom: 24px;
  text-align: center;

  @media ${breakpoint.tab} {
    font-size: ${headingFontSize.xLarge};
    margin-bottom: 48px;
  }

  @media screen and (max-width: 374px) {
    font-size: 4.5vw;
  }
`;

const ProductsWrap = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 44px auto;
  grid-gap: 12px;
  align-items: center;
  margin-bottom: 24px;

  &::before {
    content: '○';
    position: absolute;
    top: 16px;
    left: 10px;
    color: #EC404B;
    font-size: 24px;
  }

  @media ${breakpoint.tab} {
    grid-template-columns: 70px auto;
    grid-gap: 24px;
      &::before {
        top: 12px;
        left: 18px;
        font-size: 34px;
      }
    }
`;

const ProductsWrap2 = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 44px auto;
  grid-gap: 12px;
  align-items: center;
  margin-bottom: 24px;

  &::before {
    content: '×';
    position: absolute;
    top: 16px;
    left: 10px;
    color: #242D8D;
    font-size: 24px;
  }

  @media ${breakpoint.tab} {
    grid-template-columns: 70px auto;
    grid-gap: 24px;
    &::before {
      top: 12px;
      left: 18px;
      font-size: 34px;
    }
  }
`;

const ProductsText = styled.div`
  background-color: #F5F5F5;
  font-size: ${fontSize.small};
  padding: 12px 16px;

  @media ${breakpoint.tab} {
    font-size: ${fontSize.default};
    padding: 24px;
  }
`;

const BalloonImage = styled(StyledImage)`
  height: 53px;
  width: 44px;

  @media ${breakpoint.tab} {
    height: 84px;
    width: 70px;
  }
`;


// 今後の流れ
const Flow = styled.div`
  display: grid;
  grid-template-columns: 70px auto;
  grid-gap: 16px;
  background-color: #FFFFBF;
  margin-bottom: 24px;
  padding: 24px;

  @media ${breakpoint.tab} {
    grid-template-columns: 100px auto;
    grid-gap: 32px;
    max-width: 720px;
    margin: 0 auto 24px;
    padding: 32px;
  }
`;

const Flow2 = styled(Flow)`
  position: relative;
  margin-bottom: 48px;

  &::after {
    content: '';
    position: absolute;
    left: calc(50% - 8px);
    bottom: -32px;
    border-right: 12px solid transparent;
    border-bottom: 16px solid ${textColor};
    border-left: 12px solid transparent;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }
`;

const FlowInner = styled.div`
  div {
    font-size: ${headingFontSize.medium};
    font-weight: bold;
    margin-bottom: 4px;
    @media ${breakpoint.tab} {
      font-size: ${headingFontSize.large};
    }
  }
  p {
    font-size: ${fontSize.small};
    @media ${breakpoint.tab} {
      font-size: ${fontSize.default};
    }
  }
`;

const ContactH2 = styled(H2)`
  background-color: #6EC199;
  margin-top: 60px;

  &::after {
    border-bottom-color: #6EC199;
  }
`;

const FlowImage = styled(StyledImage)`
  width: 70px;

  @media ${breakpoint.tab} {
    width: 100px;
  }
`;
