import { CROCCHA_API } from 'app/src/utils';
import { useState } from 'react';

type FormData = {
  companyName: string;
  name: string;
  email: string;
  phonenumber: string;
  contents: string;
};

type Result<T> =
  | {
      isSuccess: true;
      value: T;
    }
  | {
      isSuccess: false;
    };

type Status = 'standby' | 'success' | 'error' | 'working';

export const useFetchPost = (success: () => void) => {
  const [submitStatus, setSubmitStatus] = useState<Status>('standby');

  /**
   * ファイル(画像、PDF)をAPIに投げる
   * Driveにファイルをアップロードする
   */
  const postUploadsFiles = async (
    photos: File[]
  ): Promise<Result<{ message: 'success'; ImageDriveFolderId: string }>> => {
    const ImageformData = new FormData();
    photos.forEach((photoData, index) => {
      ImageformData.append(`file_${index}`, photoData, photoData.name);
    });

    // 送信用データを設定
    const url = CROCCHA_API.SERVER_LESS_COMPANY_API + '/uploads/drive';
    const options = {
      method: 'POST',
      body: ImageformData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    delete options.headers['Content-Type'];

    return await fetch(url, options)
      .then(async (v) => {
        const res: {
          result: {
            isSuccess: true;
            value: {
              message: 'success';
              ImageDriveFolderId: string;
            };
          };
        } = await v.json();

        return { isSuccess: true, value: res.result.value };
      })
      .catch((e) => {
        console.error(e.message);
        setSubmitStatus('error');
        return { isSuccess: false };
      });
  };

  /**
   * テキスト情報をAPIをAPIに投げる
   * 主に
   * ・メール送信
   * ・slack通知
   * ・スプレッドシートへの情報の記載をおこなう
   */
  const postContactSdgsHandmade = async (
    data: FormData,
    ImageDriveFolderId: string
  ): Promise<Result<{ message: 'success' }>> => {
    const url = CROCCHA_API.SERVER_LESS_COMPANY_API + '/contact/sdgs-handmade';
    const postData = {
      ...data,
      ImageDriveFolderId,
    };

    // 送信用データを設定
    const options = {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    return await fetch(url, options)
      .then(async (v) => {
        const res: {
          result: {
            isSuccess: true;
            value: {
              message: 'success';
            };
          };
        } = await v.json();

        return { isSuccess: true, value: res.result.value };
      })
      .catch((e) => {
        console.error(e.message);
        setSubmitStatus('error');
        return { isSuccess: false };
      });
  };

  const sendMail = async (data: FormData, photos: File[]) => {
    setSubmitStatus('working');

    /**
     * フォーム送信の処理
     * 1 ファイル(画像、PDF)をAPIに投げる
     * 2 テキスト情報をAPIをAPIに投げる
     *
     * ※まとめて'multipart/form-data'でAPIに投げると日本語が文字化けするため、分割している
     *
     */

    /** 1 ファイル(画像、PDF)をAPIに投げる */
    const postUploadsFilesResult = await postUploadsFiles(photos);
    if (!postUploadsFilesResult.isSuccess) {
      setSubmitStatus('error');
      return;
    }

    /** 2 テキスト情報をAPIをAPIに投げる*/
    const result = await postContactSdgsHandmade(
      data,
      postUploadsFilesResult.value.ImageDriveFolderId
    );

    if (!result.isSuccess) {
      setSubmitStatus('error');
      return;
    }

    success();
    setSubmitStatus('success');
  };

  return { submitStatus, sendMail };
};
