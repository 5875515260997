import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import {
  mainColor,
  placeholderColor,
  breakpoint,
  primaryButtonStyle,
} from '../../theme';
import { RouteComponentProps } from '@reach/router';
import PhotosUpload from '../../molecules/PhotosUpload';

type FormData = {
  companyName: string;
  name: string;
  email: string;
  phonenumber: string;
  contents: string;
};

type SdgsHandmadeSearviceFormProps = RouteComponentProps<{ sendMail: any; submitStatus: any; }>;

const SdgsHandmadeSearviceForm: React.FC<SdgsHandmadeSearviceFormProps> = ({ sendMail, submitStatus }) => {

  const { handleSubmit, register, errors } = useForm<FormData>({
    mode: 'onChange',
  });

  const [photos, setPhotos] = useState<File[]>([]);

  const onSubmit = async (data: FormData) => {
    const submitData: FormData = {
      ...data,
      contents: data.contents?.replace(/\r?\n/g, '<br>') ?? '',
    };
    sendMail(submitData, photos);
  };

  return (
    <FormWrap>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <FieldWrap>
          <LabelWrap>
            <Label htmlFor='companyName'>
              会社名<Required>必須</Required>
            </Label>
            {errors?.companyName?.message && (
              <ErrorText>{errors.companyName.message}</ErrorText>
            )}
          </LabelWrap>
          <Input
            type='text'
            name='companyName'
            placeholder='例: 〇〇〇株式会社'
            ref={register({
              required: '会社名を入力してください',
              maxLength: {
                value: 100,
                message: '100文字以内で入力してください',
              },
            })}
            hasError={errors?.companyName?.message}
          />
        </FieldWrap>

        <FieldWrap>
          <LabelWrap>
            <Label htmlFor='name'>
              ご担当者名<Required>必須</Required>
            </Label>
            {errors?.name?.message && (
              <ErrorText>{errors.name.message}</ErrorText>
            )}
          </LabelWrap>
          <Input
            type='text'
            name='name'
            placeholder='例: 山田花子'
            ref={register({
              required: '名前を入力してください',
              maxLength: {
                value: 100,
                message: '100文字以内で入力してください',
              },
            })}
            hasError={errors?.name?.message}
          />
        </FieldWrap>

        <FieldWrap>
          <LabelWrap>
            <Label htmlFor='email'>
              メールアドレス<Required>必須</Required>
            </Label>
            {errors?.email?.message && (
              <ErrorText>{errors.email.message}</ErrorText>
            )}
          </LabelWrap>
          <Input
            type='text'
            name='email'
            placeholder='例: xxx@sample.jp'
            ref={register({
              required: '必須項目です',
              maxLength: {
                value: 100,
                message: '100文字以内で入力してください',
              },
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: 'メールアドレスの形式が正しくありません',
              },
            })}
            hasError={errors?.email?.message}
          />
        </FieldWrap>

        <FieldWrap>
          <LabelWrap>
            <Label htmlFor='phonenumber'>
              電話番号<Required>必須</Required>
            </Label>
            {errors?.phonenumber?.message && (
              <ErrorText>{errors.phonenumber.message}</ErrorText>
            )}
          </LabelWrap>
          <Input
            type='text'
            name='phonenumber'
            placeholder='例: 080XXXXXXXX'
            ref={register({
              required: '電話番号(ハイフンなし)を入力してください',
              maxLength: {
                value: 11,
                message: '11桁以内で入力してください',
              },
              pattern: {
                value:
                  /^\d{7,11}$/,
                message: '電話番号の形式が正しくありません',
              },
            })}
            hasError={errors?.phonenumber?.message}
          />
        </FieldWrap>

        <FieldWrap>
          <LabelWrap>
            <Label htmlFor='files'>
              商材の写真等 （JPG/PNG/PDF）
            </Label>
          </LabelWrap>
          <PhotosUpload name="photos" photos={photos} setPhotos={setPhotos} />
        </FieldWrap>

        <TextareaFieldWrap>
          <LabelWrap>
            <Label htmlFor='contents'>
              お問い合わせ内容<Required>必須</Required>
            </Label>
            {errors?.contents?.message && (
              <ErrorText>{errors.contents.message}</ErrorText>
            )}
          </LabelWrap>
          <Input
            as='textarea'
            name='contents'
            rows='10'
            placeholder='お問い合わせ内容をご記入ください'
            ref={register({
              required: '必須項目です',
              maxLength: {
                value: 700,
                message: '700文字以内で入力してください',
              },
            })}
            hasError={errors?.contents?.message}
          />
        </TextareaFieldWrap>

        {submitStatus === 'error' && (
          <div>
            <p>お問い合わせを送信できませんでした。</p>
            <p>
              恐れ入りますがしばらくお待ちいただき、後ほどお試しください
            </p>
          </div>
        )}

        <SubmitButton type='submit' value='お問い合わせする' />
      </form>
    </FormWrap>
  );
};

export default SdgsHandmadeSearviceForm;

const FormWrap = styled.section`
  position: relative;
  margin: 0 auto;
  max-width: 800px;
  padding: 0 0 60px;
  /* NOTE：メンテナンス時に有効にする */
  /* display: none; */

  @media ${breakpoint.tab} {
    max-width: 720px;
  }
`;

const LabelWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Label = styled.label`
  display: flex;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
`;

const Required = styled.span`
  margin-left: 5px;
  padding: 4px 8px;
  background: ${mainColor};
  border-radius: 20px;
  color: #fff;
  font-size: 11px;
  font-weight: normal;
  vertical-align: top;
`;

const ErrorText = styled.span`
  margin-bottom: 0.5rem;
  display: inline-block;
  color: ${mainColor};
  font-size: 12px;
`;

const FieldWrap = styled.div`
  margin: 0 auto 32px;
  width: 100%;

  @media ${breakpoint.tab} {
    width: 70%;
  }
`;

const inputMixinStyle = css`
  display: block;
  padding: 0.5rem 1rem;
  width: 100%;
  background: #f8f8f8;
  border: 1px solid #a2a1a1;
  border-radius: 5px;
  &::placeholder {
    color: ${placeholderColor};
  }
  &:focus {
    border: 1px solid #d1a4a1;
    box-shadow: 0px 2px 6px #3c0e0e1f;
    background: #fff;
  }
  ${(props: any) =>
    props.hasError &&
    css`
      border: 1px solid ${mainColor};
      background: #fff5f5;
      box-shadow: 0px 2px 6px #3c0e0e1f;
      color: ${mainColor};
    `}
`;

const Input: any = styled.input`
  ${inputMixinStyle};
`;

const TextareaFieldWrap = styled.div`
  margin: 0 auto 40px;
  width: 100%;

  @media ${breakpoint.tab} {
    width: 70%;
  }
`;

const SubmitButton = styled.input`
  ${primaryButtonStyle};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6EC199;
  margin: 0 auto;
  width: 220px;
  background: no-repeat,
    linear-gradient(
      135deg,
      #6EC199 0%,
      #6EC199 50%,
      #91D0B2 50%,
      #91D0B2 100%
    );
`;
