import React, { useState } from "react";
import styled from 'styled-components';
import { fontSize, mainColor, placeholderColor, subTextColor } from "../../theme";

interface PhotosUploadProps {
  name: string;
  componentRef?: (instance: HTMLInputElement | null) => void;
  photos: File[];
  setPhotos: (files: File[]) => void;
}

const PhotosUpload: React.FC<PhotosUploadProps> = ({
  name,
  componentRef,
  photos,
  setPhotos,
}: PhotosUploadProps): React.ReactElement => {
  const [isSameError, setIsSameError] = useState(false);
  const [isFileTypeError, setIsFileTypeError] = useState(false);
  const [isFileSizeError, setIsFileSizeError] = useState(false);

  const resetErrors = () => {
    setIsSameError(false);
    setIsFileTypeError(false);
    setIsFileSizeError(false);
  };

  const handleFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null || event.target.files.length === 0) {
      return;
    }
    const files = Object.values(event.target.files).concat();
    // 初期化することで同じファイルを連続で選択してもonChagngeが発動するように設定し、画像をキャンセルしてすぐに同じ画像を選ぶ動作に対応
    event.target.value = "";
    resetErrors();

    const pickedPhotos = files.filter((file) => {
      if (
        ![
          "image/gif",
          "image/jpeg",
          "image/png",
          "image/bmp",
          "image/svg+xml",
          "application/pdf",
        ].includes(file.type)
      ) {
        setIsFileTypeError(true);
        return false;
      }

      //1ファイルは最大1.5MB以下である必要がある
      if (file.size > 1572864) {
        setIsFileSizeError(true);
        return false;
      }

      const existsSameSize = photos.some((photo) => photo.size === file.size);
      if (existsSameSize) {
        setIsSameError(true);
        return false;
      }

      return true;
    });

    if (pickedPhotos.length === 0) {
      return;
    }

    let modifyPhotos = photos.concat();
    const index = +event.target.id.replace(`${name}_`, '');
    modifyPhotos[index] = pickedPhotos[0];

    setPhotos(modifyPhotos);
  };

  const handleCancel = (photoIndex: number) => {
    if (confirm("選択したファイルを削除してもよろしいですか？")) {
      resetErrors();
      const modifyPhotos = photos.concat();
      modifyPhotos.splice(photoIndex, 1);
      setPhotos(modifyPhotos);
    }
  };

  return (
    <>
      {[...Array(3)].map((_: number, index: number) =>
      (
        <LabelWrap>
          <Label htmlFor={`${name}_${index}`}>
            <Input
              type="file"
              name={name}
              id={`${name}_${index}`}
              ref={componentRef}
              accept="image/*,application/pdf"
              onChange={handleFile}
              multiple
            />
            <span>ファイルを選択</span>
          </Label>
          {photos[index] && <FileNameWrap>
            <FileName>{photos[index].name}</FileName>
            <Remove key={index} onClick={() => handleCancel(index)}>×</Remove>
          </FileNameWrap>}
        </LabelWrap>
      )
      )}
      <p>※最大3つのファイルがアップロードできます</p>
      {isSameError && (
        <ErrorText>※既に選択されたファイルと同じものは表示されません</ErrorText>
      )}
      {isFileSizeError && (
        <ErrorText>※1.5Mb以上のファイルはアップロードできません</ErrorText>
      )}
      {isFileTypeError && (
        <ErrorText>※jpg, png, pdf以外のファイル形式は表示されません</ErrorText>
      )}
    </>
  );
};

export default PhotosUpload;

const Input = styled.input`
  display: none;
`;

const LabelWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const Label = styled.label`
  display: flex;
  white-space: nowrap;

  span {
    background-color: #f8f8f8;
    border: 1px solid ${placeholderColor};
    border-radius: 20px;
    font-size: ${fontSize.default};
    padding: 6px 16px;
    cursor: pointer;
  }
`;

const FileNameWrap = styled.div`
  display: grid;
  grid-template-columns: auto 36px;
  align-items: center;
`;

const FileName = styled.div`
  color: ${subTextColor};
  font-size: ${fontSize.small};
  margin-left: 12px;
`;

const Remove = styled.button`
  background-color: rgba(0, 0, 0, .5);
  border-radius: 12px;
  color: #fff;
  font-size: ${fontSize.small};
  margin-left: 12px;
  height: 24px;
  width: 24px;
`;

const ErrorText = styled.span`
  margin-bottom: 0.5rem;
  display: inline-block;
  color: ${mainColor};
  font-size: 12px;
`;
